import { zonedTimeToUtc } from "@gymflow/helpers";
import { ApiListResponse, NoVisitReportDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export interface UseQueryNoVisitReportProps {
  api: {
    accessApi: {
      noVisitReport: (params: {
        dateFrom: string;
        dateTo: string;
        page?: number;
        size?: number;
      }) => Promise<{ data: ApiListResponse<NoVisitReportDTO> }>;
    };
  };
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  size?: number;
  tz: string;
}

export function useQueryNoVisitReport(
  { api, dateFrom, dateTo, page, size, tz }: UseQueryNoVisitReportProps,
  queryOpts?: UseQueryOptions<ApiListResponse<NoVisitReportDTO>>,
) {
  const result = useQuery({
    queryKey: reportQueryKeys.noVisit({
      dateFrom,
      dateTo,
      page,
      size,
    }),
    queryFn: async () => {
      const result = await api.accessApi.noVisitReport({
        dateFrom: zonedTimeToUtc(dateFrom as string, tz),
        dateTo: zonedTimeToUtc(dateTo as string, tz),
        page,
        size,
      });
      return result.data;
    },
    initialData: defaultPage<NoVisitReportDTO>,
    enabled: !!tz && !!dateFrom && !!dateTo,
    ...queryOpts,
  });
  return result;
}
