import { cn } from "@gymflow/helpers";
import { MouseEvent as ReactMouseEvent } from "react";

export function Checkbox({
  className,
  inputClassName,
  activeInputClassName,
  onChange,
  value,
}: {
  className?: string;
  inputClassName?: string;
  activeInputClassName?: string;
  onChange: (
    newValue: boolean,
    e: ReactMouseEvent<HTMLInputElement, MouseEvent>,
  ) => void;
  value: boolean;
}) {
  return (
    <div className={cn("flex h-6 items-center", className)}>
      <input
        type="checkbox"
        className={cn(
          "text-primary-600 focus:ring-primary-600 dark:bg-darkModeFill dark:border-darkGray-700 h-4 w-4 border-gray-300",
          inputClassName,
          {
            "!bg-primary-600 !border-primary-600": value,
          },
          {
            [activeInputClassName ?? ""]: value,
          },
        )}
        checked={value}
        onChange={() => {}}
        onClick={(e) => {
          onChange(e.currentTarget.checked, e);
        }}
      />
    </div>
  );
}
