import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  appointmentQueryKeys,
  calendarQueryKeys,
  clubStaleTime,
  useAppointmentCollectPayment,
  useAppointmentEdit,
  useAppointmentRefundPayment,
  useClub,
  useMemberPaymentMethodList,
} from "@gymflow/api";
import {
  InvoiceStatus,
  NotificationContext,
  PaymentConfirmationStatus,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import { AppointmentDTO } from "@gymflow/types";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { ReactNode, useContext, useEffect, useState } from "react";

import { ModalContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { Button, PaginatedSelect, SlideSideBar } from "../../atoms";
import { ConfirmModal } from "../../templates";
import { AppointmentDetails } from "./AppointmentDetails";
import { AppointmentNotes } from "./AppointmentNotes";
import { AppointmentPayment } from "./AppointmentPayment";
import { HostNotAvailableModal } from "./HostNotAvailableModal";
import { AppointmentRescheduleModal } from "./RescheduleModal";

interface SideBarAppointmentProps {
  onClose: () => void;
  isOpen: boolean;
  value: AppointmentDTO;
  isLoading: boolean;
}

export function SideBarAppointment({
  onClose,
  isOpen,
  value,
  isLoading,
}: SideBarAppointmentProps) {
  const settings = useClubSettings();
  const { notifyDanger, notify } = useContext(NotificationContext);
  const clubId = settings.clubId;
  const { api } = useGymflowModels();
  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });
  const { data: paymentMethods } = useMemberPaymentMethodList({
    api,
    memberId: value.appointmentUser.id,
    clubId,
  });
  const collectPaymentMutation = useAppointmentCollectPayment({ api });
  const [paymentMethodId, setPaymentMethodId] = useState<string>();

  const { setModal, hide: hideModal } = useContext(ModalContext);

  const [selectedTab, setSelectedTab] = useState<
    "DETAILS" | "PAYMENT" | "NOTES"
  >("DETAILS");
  const [paymentUnit, setPaymentUnit] = useState<"CARD" | "CREDITS">();

  const [promoCode, setPromoCode] = useState<string>();

  const [isTabOpen, setIsTabOpen] = useState(isOpen);
  useEffect(() => {
    setIsTabOpen(isOpen);
  }, [isOpen]);

  const editAppointmentMutation = useAppointmentEdit({
    api,
    tz: club?.timezone!,
  });
  const refundAppointmentMutation = useAppointmentRefundPayment({
    api,
  });
  const { show: showPaymentAuthorizationAlert } =
    usePaymentAuthorizationAlert();

  const queryClient = useQueryClient();
  return (
    <SlideSideBar
      isOpen={isTabOpen}
      hide={onClose}
      className="!w-[32rem]"
      isLoading={isLoading}
    >
      <div className="flex h-full max-h-full flex-col overflow-hidden">
        <div className="flex h-full max-h-full flex-col overflow-hidden">
          <div className="flex flex-col justify-between border-b border-gray-200 p-8">
            <div className="mb-1 flex flex-row items-center justify-between">
              <div className="text-xl font-semibold text-gray-900">
                View Appointment
              </div>

              <FontAwesomeIcon
                onClick={() => {
                  onClose();
                }}
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
            <div className="text-sm font-medium text-gray-600">
              View, update and cancel an existing appointment.
            </div>

            <div className="mt-4 flex">
              <Button
                onClick={() => setSelectedTab("DETAILS")}
                className={classNames(
                  "w-full !rounded-r-none focus:outline-none",
                  {
                    "bg-gray-100": selectedTab === "DETAILS",
                  },
                )}
              >
                Details
              </Button>
              <Button
                onClick={() => setSelectedTab("PAYMENT")}
                className={classNames(
                  "w-full !rounded-none focus:outline-none",
                  {
                    "bg-gray-100": selectedTab === "PAYMENT",
                  },
                )}
              >
                Payment
              </Button>
              <Button
                onClick={() => setSelectedTab("NOTES")}
                className={classNames(
                  "w-full !rounded-l-none focus:outline-none",
                  {
                    "bg-gray-100": selectedTab === "NOTES",
                  },
                )}
              >
                Notes
              </Button>
            </div>
          </div>

          <div className="overflow-y-auto border-b border-gray-200 py-4">
            {selectedTab === "DETAILS" && (
              <AppointmentDetails
                status={value.status}
                appointableName={value.appointable.name}
                appointablePrice={value.finalPrice}
                appointableSessionCost={value.finalSessionCost}
                hostName={
                  value.appointable.availabilityType === "STAFF"
                    ? `${value.appointmentHost?.firstName || ""} ${
                        value.appointmentHost?.lastName || ""
                      }`
                    : undefined
                }
                facilityName={
                  value.appointable.availabilityType === "FACILITY"
                    ? value.appointmentFacility?.name
                    : undefined
                }
                availabilityType={value.appointable.availabilityType}
                memberName={`${value.appointmentUser.firstName} ${value.appointmentUser.lastName}`}
                memberId={value.appointmentUser.id}
                paymentStatus={value.paymentStatus}
                startDate={value.startDate}
                endDate={value.endDate}
                currency={club?.defaultCurrency!}
                appointablePricingModel={value.appointable.pricingModel}
              />
            )}
            {selectedTab === "PAYMENT" && (
              <AppointmentPayment
                paymentStatus={value.paymentStatus}
                appointablePrice={value.finalPrice}
                appointableSessionCost={value.finalSessionCost}
                paymentUpfront={value.paymentUpfront}
                appointableId={value.appointable.id}
                appointmentId={value.id}
                paymentMethods={paymentMethods}
                memberId={value.appointmentUser.id}
                memberName={`${value.appointmentUser.firstName} ${value.appointmentUser.lastName}`}
                cardRequired={value.appointable.cardUpfront}
                setIsTabOpen={setIsTabOpen}
                setPaymentMethodId={setPaymentMethodId}
                paymentMethodId={paymentMethodId}
                setPaymentUnit={setPaymentUnit}
                paymentUnit={paymentUnit}
                pricingModel={value.pricingModel}
                setPromoCode={setPromoCode}
                promoCode={promoCode}
                appointableName={value.appointable.name}
              />
            )}
            {selectedTab === "NOTES" && (
              <AppointmentNotes
                appointmentId={value.id}
                noteList={value.noteList}
                setIsTabOpen={setIsTabOpen}
              />
            )}
          </div>
        </div>

        <div className="flex h-20 flex-row items-center justify-end gap-2 border-t border-gray-200 px-6">
          <PaginatedSelect
            className="mt-2 flex-1"
            placeholder="More Options"
            value={null}
            cacheUniqs={[value.paymentStatus]}
            onChange={async function (newValue: {
              label: ReactNode;
              value: AppointmentAction;
            }) {
              switch (newValue.value) {
                case "REFUND": {
                  try {
                    await refundAppointmentMutation.mutateAsync({
                      clubId,
                      appointmentId: value.id,
                    });
                    notify({ message: "Appointment refunded." });
                  } catch (e) {
                    notifyDanger(e);
                  }
                  break;
                }
                case "CANCEL":
                case "LATE_CANCEL": {
                  const newStatus =
                    newValue.value === "CANCEL"
                      ? "CANCELLED"
                      : "LATE_CANCELLED";
                  try {
                    await editAppointmentMutation.mutateAsync({
                      appointmentId: value.id,
                      patchedFields: { status: newStatus },
                    });

                    if (
                      ((value.finalPrice !== 0 &&
                        value.finalPrice !== undefined) ||
                        (value.finalSessionCost !== 0 &&
                          value.finalSessionCost !== undefined)) &&
                      value.paymentStatus === "PAID"
                    ) {
                      setIsTabOpen(false);
                      setModal(
                        <ConfirmModal
                          title="Issue Refund?"
                          cancelText="No"
                          confirmText="Yes"
                          onCancel={() => {
                            hideModal();
                            setIsTabOpen(true);
                            notify({ message: "Appointment cancelled." });
                          }}
                          onConfirm={async () => {
                            await refundAppointmentMutation.mutateAsync({
                              clubId,
                              appointmentId: value.id,
                            });

                            hideModal();
                            setIsTabOpen(true);
                            notify({
                              message: "Appointment cancelled and refunded.",
                            });
                          }}
                        >
                          Would you like to refund the payment or credits for
                          this appointment.
                        </ConfirmModal>,
                      );
                    }
                  } catch (e) {
                    notifyDanger(e);
                  }
                  break;
                }
                case "NO_SHOW": {
                  try {
                    await editAppointmentMutation.mutateAsync({
                      appointmentId: value.id,
                      patchedFields: { status: "NO_SHOW" },
                    });
                    notify({
                      message: "Appointment marked as no show.",
                    });
                  } catch (e) {
                    notifyDanger(e);
                  }
                  break;
                }
                case "CHECK_IN": {
                  try {
                    await editAppointmentMutation.mutateAsync({
                      appointmentId: value.id,
                      patchedFields: { status: "ATTENDED" },
                    });
                    notify({
                      message: "Appointment checked in.",
                    });
                  } catch (e) {
                    notifyDanger(e);
                  }
                  break;
                }
                case "RESCHEDULE": {
                  setIsTabOpen(false);
                  setModal(
                    <AppointmentRescheduleModal
                      appointment={value}
                      onConfirm={async (newValues) => {
                        try {
                          await editAppointmentMutation.mutateAsync({
                            appointmentId: value.id,
                            patchedFields: newValues,
                          });

                          hideModal();
                          setIsTabOpen(true);
                        } catch (e: any) {
                          hideModal();
                          if (
                            e?.response?.data?.error_message ===
                            "This staff has no available slots for this booking time."
                          ) {
                            setModal(
                              <HostNotAvailableModal
                                onConfirm={async () => {
                                  await editAppointmentMutation.mutateAsync({
                                    appointmentId: value.id,
                                    patchedFields: {
                                      ...newValues,
                                      availabilityValidationIgnored: true,
                                    },
                                  });
                                  hideModal();
                                  setIsTabOpen(true);

                                  notify({
                                    message: "Appointment rescheduled.",
                                  });
                                }}
                                onCancel={() => {
                                  hideModal();
                                  setIsTabOpen(true);
                                }}
                              />,
                            );
                          } else {
                            notifyDanger(e);
                          }
                        }
                      }}
                      onCancel={() => {
                        hideModal();
                        setIsTabOpen(true);
                      }}
                    />,
                  );
                  break;
                }
              }
            }}
            loadOptions={() => {
              const options = {
                options: [
                  {
                    label: <div className="text-error-700">Cancel</div>,
                    value: "CANCEL",
                  },
                  {
                    label: "No Show",
                    value: "NO_SHOW",
                  },
                  {
                    label: "Late Cancel",
                    value: "LATE_CANCEL",
                  },
                  {
                    label: "Check In",
                    value: "CHECK_IN",
                  },
                ] as { label: ReactNode; value: AppointmentAction }[],
              };
              if (!value.kisiRoleAssignmentId) {
                options.options.unshift({
                  label: "Reschedule",
                  value: "RESCHEDULE",
                });
              }
              if (value.paymentStatus === "PAID") {
                options.options.unshift({
                  label: "Refund",
                  value: "REFUND",
                });
              }
              return Promise.resolve(options);
            }}
          />
          <Button
            intent="primary"
            className={classNames("flex-1", {
              hidden:
                value.paymentStatus === "PAID" || selectedTab === "PAYMENT",
            })}
            onClick={() => setSelectedTab("PAYMENT")}
          >
            Checkout
          </Button>
          <Button
            intent="primary"
            className={classNames("flex-1", {
              hidden:
                value.paymentStatus === "PAID" || selectedTab !== "PAYMENT",
            })}
            disabled={
              !paymentUnit || (paymentUnit === "CARD" && !paymentMethodId)
            }
            onClick={async () => {
              try {
                const response = await collectPaymentMutation.mutateAsync({
                  appointmentId: value.id,
                  paymentMethodId:
                    paymentUnit === "CARD" ? paymentMethodId : undefined,
                  paidWithSessions:
                    paymentUnit === "CREDITS" ? true : undefined,
                  promotionCode: promoCode,
                });

                if (
                  response.invoice.status === InvoiceStatus.PaymentAuthorization
                ) {
                  setIsTabOpen(false);
                  const paymentConfirmationResult =
                    await showPaymentAuthorizationAlert({
                      paymentIntentIdForAuthorization:
                        response.invoice.paymentIntentIdForAuthorization,
                      confirmPayment:
                        api.strongCustomerAuthorizationApi.confirmPayment,
                      messageText: (
                        <>
                          The user must authorize this payment before it will be
                          processed.
                          <br />
                          Please ask the user to authorize the payment by
                          clicking the link sent to their email.
                        </>
                      ),
                    });
                  if (
                    paymentConfirmationResult.status ===
                    PaymentConfirmationStatus.Success
                  ) {
                    notify({
                      message: "The payment was confirmed",
                      type: "success",
                    });
                  } else if (
                    paymentConfirmationResult.status ===
                    PaymentConfirmationStatus.Waiting
                  ) {
                    notify({
                      message: "Awaiting payment, check later.",
                      type: "warning",
                    });
                  } else if (
                    paymentConfirmationResult.status ===
                    PaymentConfirmationStatus.Failed
                  ) {
                    notify({
                      message: "Payment Failed.",
                      type: "danger",
                    });
                  }
                }
              } catch (e: any) {
                if (e?.response?.data?.resource_not_found === "Promotion") {
                  notify({
                    type: "danger",
                    message: "Promotion code is not valid.",
                  });
                } else {
                  notifyDanger(e);
                }
              } finally {
                queryClient.invalidateQueries({
                  queryKey: appointmentQueryKeys.all(),
                });
                queryClient.invalidateQueries({
                  queryKey: calendarQueryKeys.appointments(),
                });
                setIsTabOpen(true);
              }
            }}
          >
            Take Payment
          </Button>
        </div>
      </div>
    </SlideSideBar>
  );
}

type AppointmentAction =
  | "EDIT"
  | "REFUND"
  | "CANCEL"
  | "NO_SHOW"
  | "LATE_CANCEL"
  | "CHECK_IN"
  | "RESCHEDULE";
