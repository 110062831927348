import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useAppointableDisable,
  useAppointableEdit,
  useAppointableEnable,
  useAppointableList,
} from "@gymflow/api";
import { formatCurrency } from "@gymflow/helpers";
import { AppointableDTO, AppointableDtoStatus } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { useContext, useRef, useState } from "react";
import { useHistory } from "react-router";

import { usePageSize } from "../../../hooks/usePageSize";
import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import { ModalContext, useClubSettings } from "../../../providers";
import { RouteFeature } from "../../../routes/feature";
import { RouteLayout } from "../../../routes/layout";
import useGymflowModels from "../../../store";
import { Badge, Button, TextButton } from "../../atoms";
import DropdownFilter from "../../DropdownFilter";
import { PaginatedTable } from "../../organisms/PaginatedTable";
import Switch from "../../Switch";
import { ConfirmModal } from "../../templates";
import { SettingsContainer } from "../SettingsContainer";

export function AppointableTable() {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const enableMutation = useAppointableEnable({ api });
  const disableMutation = useAppointableDisable({ api });
  const changeAppointableMutation = useAppointableEdit({ api });
  const { setModal, hide } = useContext(ModalContext);
  const [isActiveOption, setIsActiveOption] = useState<{
    label: string;
    value: AppointableDtoStatus;
  }>({
    label: "Enabled",
    value: "ACTIVE",
  });
  const history = useHistory();
  const { createClubLink } = usePortalRoutes();

  const [selectedPage, setSelectedPage] = useState(0);
  const [sort, setSort] = useState<SortingState>([]);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });

  const { data, isLoading } = useAppointableList({
    api,
    opts: {
      page: selectedPage,
      limit: pageSize,
      sort: sort?.[0]
        ? {
            field: sort[0].id,
            desc: sort[0].desc,
          }
        : undefined,
      extraParams: { status: isActiveOption.value as AppointableDtoStatus },
    },
  });

  const columnHelper = createColumnHelper<AppointableDTO>();
  const columns = [
    columnHelper.accessor("name", {}),
    columnHelper.accessor("price", {
      cell: (column) => {
        return (
          <div className="">
            {column.row.original.pricingModel === "FREE" && <>Free</>}
            {column.row.original.pricingModel === "FIXED" &&
              column.row.original.price &&
              formatCurrency(
                column.row.original.price,
                settings.defaultCurrency,
              )}
          </div>
        );
      },
    }),
    columnHelper.accessor("duration", {
      cell: (column) => {
        return `${column.row.original.duration} minutes`;
      },
    }),
    columnHelper.accessor("appointableCategory.name", {
      header: "Category",
      cell: (column) => {
        return <Badge>{column.row.original.appointableCategory.name}</Badge>;
      },
    }),
    columnHelper.accessor("allowOnlineBookings", {
      header: "On Web",
      cell: (cell) => {
        return (
          <div className="flex items-center justify-start">
            <Switch
              checked={cell.getValue()}
              onChange={(checked) => {
                changeAppointableMutation.mutateAsync({
                  appointableId: cell.row.original.id,
                  patchedFields: { allowOnlineBookings: checked },
                });
              }}
            />
          </div>
        );
      },
    }),

    columnHelper.accessor("allowAppBookings", {
      header: "On App",
      cell: (cell) => {
        return (
          <div className="flex items-center justify-start">
            <Switch
              checked={cell.getValue()}
              onChange={(checked) => {
                changeAppointableMutation.mutateAsync({
                  appointableId: cell.row.original.id,
                  patchedFields: { allowAppBookings: checked },
                });
              }}
            />
          </div>
        );
      },
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (props) => {
        const isEnabled = props.row.original.status === "ACTIVE";
        const id = props.row.original.id;
        const renderBody = () => {
          let text_part1 = "";

          if (isEnabled) {
            text_part1 = "Are you sure you want to disable this appointment?";
          } else {
            text_part1 = "Are you sure you want to enable this appointment?";
          }
          return <div className="mt-2 text-sm text-gray-600">{text_part1}</div>;
        };

        return (
          <div className="flex">
            <TextButton
              className="px-0"
              onClick={() => {
                history.push(
                  createClubLink(
                    RouteLayout.Staff,
                    RouteFeature.AppointableWizard,
                    `/${props.row.original.id}`,
                  ),
                );
              }}
            >
              Edit
            </TextButton>
            <TextButton
              onClick={() => {
                setModal(
                  <ConfirmModal
                    type={isEnabled ? "danger" : "default"}
                    title={
                      isEnabled ? "Disable Appointment" : "Enable Appointment"
                    }
                    onConfirm={() => {
                      if (isEnabled) {
                        disableMutation.mutateAsync(id);
                      } else {
                        enableMutation.mutateAsync(id);
                      }
                      hide();
                    }}
                    onCancel={() => {
                      hide();
                    }}
                  >
                    {renderBody()}
                  </ConfirmModal>,
                );
              }}
            >
              {isEnabled ? "Disable" : "Enable"}
            </TextButton>
          </div>
        );
      },
    }),
  ];

  return (
    <SettingsContainer
      title="Appointments"
      subTitle="Create, Update and manage your club's appointments."
      actions={
        <>
          <DropdownFilter
            className="mt-0"
            options={[
              {
                label: "Enabled",
                value: "ACTIVE",
              },
              {
                label: "Disabled",
                value: "INACTIVE",
              },
            ]}
            value={isActiveOption}
            onChange={(v: any) => setIsActiveOption(v)}
          />
          <Button
            className="mt-0"
            intent="secondary"
            onClick={() => {
              history.push(
                createClubLink(
                  RouteLayout.Staff,
                  RouteFeature.AppointableWizard,
                ),
              );
            }}
          >
            <div className="flex flex-row items-center gap-x-2">
              <FontAwesomeIcon icon={faPlus} />
              <div>Appointment</div>
            </div>
          </Button>
        </>
      }
    >
      <PaginatedTable
        tableProps={{
          data: data.content,
          columns: columns,
          pageCount: data.totalPages,
          pageIndex: data.number,
          onSortingChange: setSort,
          sort: sort,
          isFetching: isLoading,
          pageSize: pageSize,
          tableContainerRef,
        }}
        hasPreviousPage={data?.number > 0}
        hasNextPage={data?.number < data?.totalPages - 1}
        goToNextPage={() => {
          setSelectedPage((e) => e + 1);
        }}
        goToPreviousPage={() => {
          setSelectedPage((e) => e - 1);
        }}
      />
    </SettingsContainer>
  );
}
