import { zonedTimeToUtc } from "@gymflow/helpers";
import { VisitPercentageDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { reportQueryKeys } from "./reportQueryKeys";

export interface UseQueryVisitPercentageProps {
  api: {
    accessApi: {
      visitPercentage: (params: {
        dateFrom: string;
        dateTo: string;
      }) => Promise<{ data: VisitPercentageDTO }>;
    };
  };
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  size?: number;
  tz: string;
}

export function useQueryVisitPercentange(
  { api, dateFrom, dateTo, tz }: UseQueryVisitPercentageProps,
  queryOpts?: UseQueryOptions<VisitPercentageDTO>,
) {
  const result = useQuery({
    queryKey: reportQueryKeys.visitPercentage({
      dateFrom,
      dateTo,
    }),
    queryFn: async () => {
      const result = await api.accessApi.visitPercentage({
        dateFrom: zonedTimeToUtc(dateFrom as string, tz),
        dateTo: zonedTimeToUtc(dateTo as string, tz),
      });
      return result.data;
    },
    enabled: !!tz && !!dateFrom && !!dateTo,
    ...queryOpts,
  });
  return result;
}
