import { cn } from "@gymflow/helpers";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { ReactNode } from "react";

export function Breadcrumbs({
  pages,
  activeIdx = 0,
}: {
  pages: {
    icon?: ({ className }: { className?: string }) => ReactNode;
    name: string;
  }[];
  activeIdx?: number;
}) {
  return (
    <nav aria-label="Breadcrumb" className="flex">
      <ol className="flex items-center">
        {pages.map((page, idx) => {
          const Icon = page.icon;
          return (
            <li key={page.name}>
              <div className="flex items-center">
                {idx !== 0 && (
                  <ChevronRightIcon
                    aria-hidden="true"
                    className="dark:text-grayDark-400 mx-2 h-5 w-5 shrink-0 text-gray-400"
                  />
                )}
                <div
                  className={cn(
                    "text-secondary-600 dark:text-grayDark-400 dark:border-grayDark-200 flex items-center gap-1.5 rounded-lg border border-[#E4E7EC] px-3 py-2 text-sm font-semibold",
                    {
                      "text-secondary-700 dark:bg-secondary-600 bg-[#F4EFFD] dark:text-[#F4EFFD]":
                        activeIdx === idx,
                    },
                  )}
                >
                  {Icon && (
                    <Icon
                      className={cn(
                        "stroke-secondary-600 dark:stroke-grayDark-400",
                        {
                          "stroke-secondary-700 dark:stroke-[#F4EFFD]":
                            activeIdx === idx,
                        },
                      )}
                    />
                  )}
                  <div className="">{`${idx + 1}. ${page.name}`}</div>
                </div>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
