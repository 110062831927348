import { NotificationContext } from "@gymflow/common";
import { useContext } from "react";

import { Button, CardIcon, CheckCircleIcon, LockIcon } from "../../atoms";

export function MigrationOverview({
  isTokenValid,
  needsPasswordSet,
  needsPaymentMethod,
  onNextButtonClick,
  onProcessAlreadyFinished,
}: {
  isTokenValid: boolean;
  needsPasswordSet: boolean;
  needsPaymentMethod: boolean;
  onNextButtonClick: () => void;
  onProcessAlreadyFinished: () => void;
}) {
  const steps = [];
  if (needsPasswordSet) {
    steps.push({
      icon: <LockIcon className="h-6 w-6" pathClassName="stroke-gray-500" />,
      title: "Password",
      subtitle: "Create a password to access your account",
    });
  }
  if (needsPaymentMethod) {
    steps.push({
      icon: <CardIcon className="h-6 w-6" pathClassName="stroke-gray-500" />,
      title: "Card details",
      subtitle: "Add card details in your account",
    });
  }

  const { notify } = useContext(NotificationContext);
  if (!isTokenValid) {
    notify({ message: "This invite link is not valid.", type: "danger" });
    return null;
  }

  if (!needsPasswordSet && !needsPaymentMethod) {
    onProcessAlreadyFinished();
    return null;
  }

  return (
    <div className="mt-16 flex justify-center">
      <div className="flex max-w-[20.5rem] flex-col gap-8">
        <div>
          <div className="flex flex-col items-center gap-4">
            <div className="flex h-14 w-14 items-center justify-center rounded-[32rem] border-8 border-[#ECFDF3] bg-[#DCFAE6] dark:border-[#053321] dark:bg-[#074D31]">
              <CheckCircleIcon
                className="h-7 w-7"
                pathClassName="stroke-[#079455] dark:stroke-[#17B26A]"
              />
            </div>
            <div className="flex flex-col items-center gap-2">
              <div className="text-darkModeFill dark:text-grayDark-900 text-center text-xl font-bold">
                Your Email is Verified
              </div>
              <div className="dark:text-grayDark-400 text-center text-base font-medium text-gray-500">
                Please follow the steps below to complete the transfer.
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="dark:bg-darkModeFill dark:border-grayDark-200 rounded-2xl border border-[#e4e7ec] bg-white p-4">
            <div className="flow-root pb-7">
              <ul className="-mb-8">
                {steps.map((step, stepIdx) => (
                  <li key={step.title}>
                    <div className="relative pb-1">
                      {stepIdx !== steps.length - 1 ? (
                        <span
                          aria-hidden="true"
                          className="dark:bg-grayDark-200 absolute left-6 top-4 -ml-px h-full w-0.5 bg-gray-200"
                        />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div className="dark:border-grayDark-200 dark:bg-darkModeFill flex h-12 w-12 items-center justify-center rounded-xl border border-[#E4E7EC] bg-white">
                          {step.icon}
                        </div>
                        <div className="flex min-w-0 flex-1 flex-col justify-center">
                          <div className="text-darkModeFill dark:text-grayDark-900 text-sm font-semibold">
                            {step.title}
                          </div>
                          <div className="dark:text-grayDark-400 text-xs font-normal text-gray-500">
                            {step.subtitle}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex">
            <Button
              intent="secondary"
              className="flex-1"
              onClick={onNextButtonClick}
            >
              Follow Steps
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
