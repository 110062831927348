import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ProductWithCategoryName,
  useProductDisable,
  useProductEdit,
  useProductEnable,
  useProductList,
} from "@gymflow/api";
import { isMobile } from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { ProductStatus } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { usePageSize } from "apps/portal/src/hooks";
import { useClubSettings } from "apps/portal/src/providers";
import { useContext, useEffect, useRef, useState } from "react";
import { useWindowSize } from "usehooks-ts";

import useGymflowModels from "../../../store";
import { Badge, Button, PaginatedSelect } from "../../atoms";
import { PaginatedTable } from "../../organisms/PaginatedTable";
import Switch from "../../Switch";
import { SettingsContainer } from "../SettingsContainer";
import { ProductFormSidebarProviderContext } from "./ProductFormSidebarProvider";

export function ProductsTab() {
  const settings = useClubSettings();
  const [currentPage, setCurrentPage] = useState(0);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });
  const [sort, setSort] = useState<SortingState>([]);
  const [statusOptionSelected, setStatusOptionSelected] =
    useState<ProductStatusOption>({
      label: "Enabled",
      value: "AVAILABLE",
    });

  const { api } = useGymflowModels();
  const { data, isLoading } = useProductList({
    api,
    opts: {
      page: currentPage,
      limit: pageSize,
      sort: sort?.[0]
        ? {
            field: sort[0].id === "card" ? "name" : sort[0].id,
            desc: sort[0].desc,
          }
        : { field: "createdDate", desc: true },
      extraParams: {
        status: statusOptionSelected.value,
      },
    },
  });
  const enableProductMutation = useProductEnable({ api });
  const disableProductMutation = useProductDisable({ api });
  const changeProductMutation = useProductEdit({ api });

  const [editingProduct, setEditingProduct] =
    useState<ProductWithCategoryName>();
  const { showProductForm } = useContext(ProductFormSidebarProviderContext);
  useEffect(() => {
    if (editingProduct) {
      showProductForm({
        currentProduct: editingProduct as any,
        onClose: () => {
          setEditingProduct(undefined);
        },
      });
    }
  }, [editingProduct, showProductForm]);
  const windowSize = useWindowSize({ debounceDelay: 100 });
  const columnHelper =
    createColumnHelper<NonNullable<typeof data>["content"][number]>();
  const actionButtons = (item: NonNullable<typeof data>["content"][number]) => (
    <>
      <Button
        intent="link"
        className="h-6 w-fit min-w-0"
        onClick={() => {
          setEditingProduct(item);
        }}
      >
        Edit
      </Button>
      <Button
        intent="linkWarning"
        className="h-6"
        showSpinner={
          disableProductMutation.isLoading || enableProductMutation.isLoading
        }
        onClick={async () => {
          if (statusOptionSelected.value === "AVAILABLE") {
            await disableProductMutation.mutateAsync(item.id);
          } else {
            await enableProductMutation.mutateAsync(item.id);
          }
        }}
      >
        {statusOptionSelected.value === "AVAILABLE" ? "Disable" : "Enable"}
      </Button>
    </>
  );
  const desktopColumns = [
    columnHelper.accessor("name", {
      cell: (column) => (
        <div className="flex items-center gap-3">
          {column.row.original.picture && (
            <div className="h-10 w-10 overflow-hidden rounded-xl border border-[#e4e7ec]">
              <img src={column.row.original.picture} alt={column.getValue()} />
            </div>
          )}
          <div>{column.getValue()}</div>
        </div>
      ),
    }),
    columnHelper.accessor("price", {
      cell: (column) => (
        <div>{formatCurrency(column.getValue(), settings.defaultCurrency)}</div>
      ),
    }),
    columnHelper.accessor(
      (e) => ({ stockType: e.stockType, stockQuantity: e.stockQuantity }),
      {
        id: "stockQuantity",
        header: "SOH",
        cell: (column) => {
          const { stockType, stockQuantity } = column.getValue();
          return <div>{stockType === "UNLIMITED" ? "∞" : stockQuantity}</div>;
        },
      },
    ),
    columnHelper.accessor("categoryName", {
      header: "Category",
      cell: (column) => <Badge>{column.getValue()}</Badge>,
      enableSorting: false,
    }),
    columnHelper.accessor("allowWebPurchases", {
      header: "On Web",
      cell: (cell) => (
        <div className="flex items-center justify-start">
          <Switch
            checked={cell.getValue()}
            onChange={(checked) => {
              changeProductMutation.mutateAsync({
                productId: cell.row.original.id,
                patchedFields: { allowWebPurchases: checked },
              });
            }}
          />
        </div>
      ),
    }),
    columnHelper.accessor("allowAppPurchases", {
      header: "On App",
      cell: (cell) => (
        <div className="flex items-center justify-start">
          <Switch
            checked={cell.getValue()}
            onChange={(checked) => {
              changeProductMutation.mutateAsync({
                productId: cell.row.original.id,
                patchedFields: { allowAppPurchases: checked },
              });
            }}
          />
        </div>
      ),
    }),

    columnHelper.accessor((e) => e, {
      id: "actions",
      header: "Actions",
      cell: (column) => {
        return (
          <div className="flex items-center justify-start">
            <div className="flex flex-wrap items-center justify-end gap-x-2">
              {actionButtons(column.getValue())}
            </div>
          </div>
        );
      },
      enableSorting: false,
    }),
  ];

  const mobileColumns = [
    columnHelper.accessor((e) => e, {
      id: "card",
      header: () => <div>Name</div>,
      cell: (column) => {
        const item = column.getValue();
        return (
          <div className="flex w-full flex-col justify-center gap-y-2 py-2">
            <div>
              <div className="text-lg font-semibold">{item.name}</div>
              <div className="text-sm text-gray-600">
                {formatCurrency(item.price, settings.defaultCurrency)}
              </div>
            </div>
            <div className="flex flex-row items-center gap-x-3">
              {actionButtons(item)}
            </div>
          </div>
        );
      },
    }),
  ];
  return (
    <SettingsContainer
      title="Products"
      subTitle="Create, Update and manage your available products list."
      actions={
        <>
          <PaginatedSelect
            className="h-11 bg-white"
            placeholder="Enabled"
            value={statusOptionSelected}
            onChange={(value) => setStatusOptionSelected(value)}
            loadOptions={async () => {
              return {
                options: [
                  {
                    label: "Enabled",
                    value: "AVAILABLE",
                  },
                  {
                    label: "Disabled",
                    value: "UNAVAILABLE",
                  },
                ] satisfies ProductStatusOption[],
              };
            }}
          />
          <Button
            intent="secondary"
            className="mt-0 text-white"
            onClick={() => {
              showProductForm({});
            }}
          >
            <div className="flex flex-row items-center gap-x-2">
              <FontAwesomeIcon icon={faPlus} />
              <div>Product</div>
            </div>
          </Button>
        </>
      }
    >
      <PaginatedTable
        tableProps={{
          data: data?.content ?? [],
          columns: !isMobile(windowSize.width) ? desktopColumns : mobileColumns,
          pageCount: data?.totalPages,
          pageIndex: data?.number,
          onSortingChange: setSort,
          sort: sort,
          isFetching: isLoading,
          pageSize: pageSize,
          tableContainerRef,
        }}
        hasNextPage={!!data && data?.number < data?.totalPages - 1}
        hasPreviousPage={!!data && data?.number > 0}
        goToNextPage={() => {
          setCurrentPage(currentPage + 1);
        }}
        goToPreviousPage={() => {
          setCurrentPage(currentPage - 1);
        }}
      />
    </SettingsContainer>
  );
}

interface ProductStatusOption {
  readonly value: ProductStatus;
  readonly label: string;
}
