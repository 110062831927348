import {
  clubStaleTime,
  useClubAsPublic,
  useClubHostedPagesSettingsAsPublic,
  useMutationMigrationCardPaymentMethod,
} from "@gymflow/api";
import { StripeCard } from "@gymflow/common";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useFormik } from "formik";
import { ReactNode, useState } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  Button,
  CardIcon,
  CheckCircleIcon,
  DangerIcon,
  TextInput,
} from "../../atoms";
import { Breadcrumbs } from "./Breadcrumbs";

export function MigrationSetPaymentMethod({
  needsPasswordSet,
  invitationToken,
  onSuccess,
}: {
  needsPasswordSet: boolean;
  invitationToken: string;
  onSuccess: () => void;
}) {
  const pages: {
    name: string;
    icon: (props: { className?: string }) => ReactNode;
  }[] = [];

  if (needsPasswordSet) {
    pages.push({
      name: "Password",
      icon: ({ className }) => <CheckCircleIcon pathClassName={className} />,
    });
  }

  pages.push({
    name: "Card",
    icon: ({ className }) => <CardIcon pathClassName={className} />,
  });

  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const { data: club } = useClubAsPublic({ api, clubId: settings.clubId });

  const [stripePromise] = useState(
    loadStripe(settings.stripe_api_key, {
      stripeAccount: club?.stripeApplicationId,
    }),
  );

  return (
    <div className="flex flex-col">
      <div className="dark:bg-darkModeFill dark:border-b-grayDark-100 flex justify-center border-b border-b-gray-300 bg-white py-6">
        <Breadcrumbs pages={pages} activeIdx={needsPasswordSet ? 1 : 0} />
      </div>
      <div className="flex justify-center">
        <Elements stripe={stripePromise}>
          <Form invitationToken={invitationToken} onSuccess={onSuccess} />
        </Elements>
      </div>
    </div>
  );
}

function Form({
  invitationToken,
  onSuccess,
}: {
  invitationToken: string;
  onSuccess: () => void;
}) {
  const { api } = useGymflowModels();
  const { mutateAsync: setPaymentMethod } =
    useMutationMigrationCardPaymentMethod({
      api,
    });

  const { clubId } = useClubSettings();
  const { data: hostedPagesSettings } = useClubHostedPagesSettingsAsPublic(
    { api, clubId },
    { staleTime: clubStaleTime },
  );

  const formik = useFormik<{ cardholdersName: string; validCard: boolean }>({
    initialValues: { cardholdersName: "", validCard: false },
    onSubmit: async (values) => {
      try {
        if (!stripe || !elements) {
          return;
        }
        const paymentMethod = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement)!,
          billing_details: { name: values.cardholdersName },
        });

        if (paymentMethod.error) {
          formik.setFieldError("validCard", paymentMethod.error.message);
        } else if (paymentMethod.paymentMethod) {
          await setPaymentMethod({
            invitationToken,
            paymentMethodId: paymentMethod.paymentMethod.id,
          });

          onSuccess();
        }
      } catch {}
    },
    validationSchema: toFormikValidationSchema(schema),
  });

  const stripe = useStripe();
  const elements = useElements();
  return (
    <div className="mt-16 flex max-w-sm flex-col items-center justify-center gap-6">
      <div className="flex flex-col items-center gap-1.5">
        <div className="text-darkModeFill dark:text-grayDark-900 text-xl font-bold">
          Add Card Details
        </div>
        <div className="dark:text-grayDark-400 text-center text-base font-medium text-gray-500">
          We couldn&apos;t find a card on your account,
          <br />
          please add one.
        </div>
      </div>
      <div className="w-100 dark:bg-grayDark-100 dark:border-grayDark-200 flex items-center justify-center gap-2 rounded-xl bg-gray-100 px-3 py-1.5 dark:border">
        <div>
          <div className=" dark:bg-grayDark-25 rounded-3xl border-4 border-[#E4E7EC] bg-gray-300 p-2 dark:border-[#182230]">
            <DangerIcon
              className="h-4 w-4"
              pathClassName="stroke-gray-500 dark:stroke-grayDark-400"
            />
          </div>
        </div>
        <div className="text-darkModeFill dark:text-grayDark-900 text-base font-semibold">
          Your card will not be charged
        </div>
      </div>
      <div className="w-100 flex flex-col gap-4">
        <div className="flex flex-col gap-1.5">
          <label
            className="text-darkModeFill dark:text-grayDark-900 !m-0 text-sm font-medium"
            htmlFor="cardholdersName"
          >
            Card holder&apos;s name
          </label>
          <TextInput
            placeholder="Card holder's name"
            autoComplete="off"
            maxLength={128}
            name="cardholdersName"
            type="text"
            value={formik.values.cardholdersName}
            onChange={({ currentTarget: { value } }) => {
              formik.setFieldValue("cardholdersName", value);
            }}
          />
          {formik.submitCount > 0 && formik.errors.cardholdersName && (
            <div className="text-error-600 mt-1.5 text-sm font-normal">
              {formik.errors.cardholdersName}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-1.5">
          <label
            className="text-darkModeFill dark:text-grayDark-900 !m-0 text-sm font-medium"
            htmlFor="cardholdersName"
          >
            Card details
          </label>
          <StripeCard
            onChange={(card) => {
              if (card.error) {
                formik.setFieldValue("validCard", false);
                if (card.error) {
                  formik.setFieldError("validCard", card.error.message);
                }
              } else {
                formik.setFieldValue("validCard", true);
              }
            }}
            className="!h-11 bg-white !px-3.5 !py-2.5"
            style={
              hostedPagesSettings?.settings?.darkMode
                ? {
                    base: {
                      color: "#f5f5f6",
                      "::placeholder": {
                        color: "#85888e",
                      },
                    },
                  }
                : {
                    base: {
                      backgroundColor: "white",
                      color: "#101828",
                      "::placeholder": {
                        color: "#98a2b3",
                      },
                    },
                  }
            }
          />

          {formik.submitCount > 0 && formik.errors.validCard && (
            <div className="text-error-600 mt-1.5 text-sm font-normal">
              {formik.errors.validCard}
            </div>
          )}
        </div>
      </div>
      <div className="w-100 flex">
        <Button
          intent="secondary"
          className="flex-1"
          onClick={() => {
            formik.submitForm();
          }}
          showSpinner={formik.isSubmitting}
        >
          Add
        </Button>
      </div>
    </div>
  );
}

const schema = z.object({
  cardholdersName: z.string().min(2),
  validCard: z.literal(true, {
    errorMap: () => ({ message: "Card is not valid." }),
  }),
});
