import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { ApiListResponse, VisitTotalReportDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export interface UseQueryVisitTotalReportProps {
  api: {
    accessApi: {
      visitTotalReport: (params: {
        dateFrom: string;
        dateTo: string;
        sort?: any;
        page?: number;
        size?: number;
      }) => Promise<{ data: ApiListResponse<VisitTotalReportDTO> }>;
    };
  };
  dateFrom?: string;
  dateTo?: string;
  sort?: any;
  page?: number;
  size?: number;
  tz: string;
}

export function useQueryVisitTotalReport(
  {
    api,
    dateFrom,
    dateTo,
    sort,
    page,
    size,
    tz,
  }: UseQueryVisitTotalReportProps,
  queryOpts?: UseQueryOptions<ApiListResponse<VisitTotalReportDTO>>,
) {
  const result = useQuery({
    queryKey: reportQueryKeys.visitTotal({
      dateFrom,
      dateTo,
      sort,
      page,
      size,
    }),
    queryFn: async () => {
      const result = await api.accessApi.visitTotalReport({
        dateFrom: zonedTimeToUtc(dateFrom as string, tz),
        dateTo: zonedTimeToUtc(dateTo as string, tz),
        sort,
        page,
        size,
      });
      return result.data;
    },
    initialData: defaultPage<VisitTotalReportDTO>,
    select: (data) => {
      return {
        ...data,
        content: data.content.map(({ checkInDate, checkOutDate, ...rest }) => {
          return {
            ...rest,
            checkInDate: utcToZonedTime(checkInDate, tz),
            checkOutDate: checkOutDate && utcToZonedTime(checkOutDate, tz),
          };
        }),
      };
    },
    enabled: !!tz && !!dateFrom && !!dateTo,
    ...queryOpts,
  });
  return result;
}
