import { cn } from "@gymflow/helpers";
import { cva, VariantProps } from "class-variance-authority";

const baseClasses =
  "block h-11 w-full flex-1 rounded-lg py-2.5 px-3.5 text-gray-900 ring-1 ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-inset sm:text-sm sm:leading-6 dark:bg-darkModeFill dark:placeholder:text-darkGray-500 dark:!text-darkGray-50 border-0";
const defaultColors = "ring-gray-300 dark:!ring-darkGray-700";

export const TextInputClassNames = cn(baseClasses, defaultColors);

const inputVariants = cva(baseClasses, {
  variants: {
    state: {
      default: defaultColors,
      error: "ring-error-200",
    },
  },
  defaultVariants: {
    state: "default",
  },
});

interface TextInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "type">,
    VariantProps<typeof inputVariants> {
  type: "email" | "number" | "text" | "password" | "search" | "tel" | "url";
}

export function TextInput({
  className,
  type,
  state,
  ...props
}: TextInputProps) {
  return (
    <input
      type={type}
      className={cn(inputVariants({ state }), className, {
        "bg-gray-100": props?.disabled,
      })}
      {...props}
    />
  );
}

TextInput.defaultProps = {
  type: "text",
};
