import endpoints from "./endpoints";
import { canFind } from "./features/apiResource";

const accessApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    http: axiosInstance,
    baseUrl: `${clubPrefix}${endpoints.access}`,
    apiUrl,
  };

  return Object.assign(state, canFind(state), {
    csv(filters) {
      const url = clubPrefix + `${endpoints.access}/csv`;
      return state.http.get(url, {
        params: filters,
      });
    },
    getCheckIn(userMemberId) {
      const url =
        `${clubPrefix}${endpoints.access}/check-in/:memberId/status`.replace(
          ":memberId",
          userMemberId,
        );
      return state.http.get(url);
    },
    checkIn(userMemberId, payload) {
      const url = `${clubPrefix}${endpoints.access}/check-in/:memberId`.replace(
        ":memberId",
        userMemberId,
      );
      return state.http.put(url, payload);
    },
    checkOut(userMemberId) {
      const url =
        `${clubPrefix}${endpoints.access}/check-out/:memberId`.replace(
          ":memberId",
          userMemberId,
        );
      return state.http.put(url);
    },
    visitTotalReport({ dateFrom, dateTo, sort, page, size }) {
      const url = `${clubPrefix}visit/total`;
      const config = {
        params: { dateFrom, dateTo, page, size },
      };
      if (sort) {
        config.params.sort = state.createSortParam(sort.field, sort.desc);
      }
      return state.http.get(url, config);
    },
    visitTotalReportCsv({ dateFrom, dateTo }) {
      const url = `${clubPrefix}visit/total/csv`;
      return state.http.get(url, { params: { dateFrom, dateTo } });
    },
    noVisitReport({ dateFrom, dateTo, page, size }) {
      const url = `${clubPrefix}visit/no-visit`;
      return state.http.get(url, { params: { dateFrom, dateTo, page, size } });
    },
    noVisitReportCsv({ dateFrom, dateTo }) {
      const url = `${clubPrefix}visit/no-visit/csv`;
      return state.http.get(url, { params: { dateFrom, dateTo } });
    },
    visitPercentage({ dateFrom, dateTo }) {
      const url = `${clubPrefix}visit/percentage`;
      return state.http.get(url, { params: { dateFrom, dateTo } });
    },
  });
};

export default accessApi;
