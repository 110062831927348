import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor as LibMouseSensor,
  pointerWithin,
  TouchSensor as LibTouchSensor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  leadStatusListQueryFn,
  useInfiniteQueryLeadList,
  useLeadNote,
  useMutationLeadStatus,
  useMutationLeadStatusOrder,
  useNoteCreate,
  useQueryLeadStatus,
  useTaskCreate,
  useTaskDelete,
  useTaskEdit,
} from "@gymflow/api";
import {
  NotificationContext,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
} from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import { LeadPipelineItem, PresetLeadStatusType } from "@gymflow/types";
import moment from "moment-timezone";
import {
  MouseEvent,
  RefObject,
  TouchEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";

import { usePortalRoutes } from "../../../../hooks";
import { useEditOrCreateTask } from "../../../../hooks/useEditOrCreateTask";
import { useClubSettings } from "../../../../providers";
import { RouteFeature, RouteLayout } from "../../../../routes";
import useGymflowModels from "../../../../store";
import { CheckDoneIcon, DragAndDropIcon, FilePlusIcon } from "../../../atoms";
import { MemberCard } from "../../../organisms";
import { LeadActionsDropdown } from "../LeadActionsDropdown";
import { LeadBadge } from "../LeadBadge";
import { LeadFilters } from "../LeadPipelineFilterSidebar";
import { statusColors } from "../statusColors";
import { DraggableOverlay } from "./DraggableOverlay";
import { LaneActionsDropdown } from "./LaneActionsDropdown";

export type LeadPipelineProps = {
  filters: LeadFilters;
};

export const LeadPipeline = ({ filters }: LeadPipelineProps) => {
  const { api } = useGymflowModels();
  const { data: originalLanes } = useQueryLeadStatus({ api });

  const { lanesToShow, defaultStatus } = useMemo(() => {
    if (!originalLanes) {
      return { lanesToShow: undefined, defaultStatus: [] };
    }

    const filtered = originalLanes
      .filter((lane) => lane.presetType !== "DEAL_CLOSED")
      .filter((lane) => lane.presetType !== "DEAL_LOST");

    return {
      lanesToShow: filtered.sort((a, b) => {
        return a.statusOrder - b.statusOrder;
      }),
      defaultStatus: filtered.map((lane) => lane.id),
    };
  }, [originalLanes]);

  const { timezone } = useClubSettings();
  const {
    data: originalLeads,
    hasNextPage: hasMoreLeads,
    fetchNextPage: fetchMoreLeads,
  } = useInfiniteQueryLeadList(
    {
      api,
      opts: {
        includeNotCompleteTasks: true,
        leadStatusId:
          filters?.leadStatus && filters.leadStatus.length > 0
            ? filters.leadStatus.map((status) => status.value)
            : defaultStatus,
        leadSourceId:
          filters?.leadSource && filters.leadSource.length > 0
            ? filters.leadSource.map((source) => source.value)
            : undefined,
        createdFrom: filters?.createdFrom
          ? moment(filters.createdFrom, "YYYY-MM-DD")
              .startOf("day")
              .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
          : undefined,
        createdTo: filters?.createdTo
          ? moment(filters.createdTo, "YYYY-MM-DD")
              .endOf("day")
              .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
          : undefined,
        smsCommunication: filters?.smsCommunication,
        emailCommunication: filters.emailCommunication,
        limit: 100
      },
      tz: timezone,
    },
    { enabled: !!lanesToShow },
  );

  useEffect(() => {
    if (hasMoreLeads) {
      fetchMoreLeads();
    }
  }, [hasMoreLeads, originalLeads?.pageParams.length, fetchMoreLeads]);

  const {
    changeLeadStatusMutation: { mutateAsync: changeStatus },
  } = useMutationLeadStatus({ api });
  const { mutateAsync: changeOrder } = useMutationLeadStatusOrder({ api });

  const [lanes, setLanes] = useState<Lane[]>([]);

  const { notifyDanger } = useContext(NotificationContext);

  const [isDraggingLead, setIsDraggingLead] = useState(false);
  const onDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (event.over) {
        if (event.active.data.current?.["objectType"] === "lead") {
          setIsDraggingLead(false);
          try {
            const overData = event.over.data.current as DragEventLaneData;
            const activeData = event.active.data.current as DragEventLeadData;
            if (activeData.leadStatusId === overData.leadStatusId) {
              return;
            }
            await changeStatus({
              newColumn: overData.leadStatusId,
              leadId: activeData.leadId,
            });
            const oldLane = lanes.find(
              (lane) => lane.id === activeData.leadStatusId,
            );
            const newLane = lanes.find(
              (lane) => lane.id === overData.leadStatusId,
            );

            if (oldLane && newLane) {
              const leadToMove = oldLane.leads.find(
                (lead) => lead.leadId === activeData.leadId,
              );
              if (leadToMove) {
                setLanes(
                  lanes.map((lane) => {
                    if (lane.id === oldLane.id) {
                      lane.leads = lane.leads.filter(
                        (lead) => lead.leadId !== leadToMove.leadId,
                      );
                    }
                    if (lane.id === newLane.id) {
                      lane.leads.push(leadToMove);
                    }
                    return lane;
                  }),
                );
              }
            }
          } catch (e) {
            notifyDanger(e);
          }
        } else if (event.active.data.current?.["objectType"] === "lane") {
          const overData = event.over.data.current as DragEventLaneData;
          const activeData = event.active.data.current as DragEventLaneData;

          if (overData.isDraggable) {
            try {
              await changeOrder({
                statusId: activeData.leadStatusId,
                statusOrder: overData.order,
              });
            } catch (e) {
              notifyDanger(e);
            }
          }
        }
      }
    },
    [lanes],
  );

  useEffect(() => {
    const lanes: Lane[] = [];

    if (lanesToShow && originalLeads) {
      lanesToShow.forEach((lane) => {
        lanes.push({
          id: lane.id,
          name: lane.name,
          order: lane.statusOrder,
          isDraggable: lane.editableOrder,
          presetType: lane.presetType,
          leads: originalLeads.pages
            .flatMap((t) => t.content)
            .filter((lead) => lead.leadStatusId === lane.id),
          isDeletable: lane.deletable,
        });
      });
    }

    setLanes(lanes);
  }, [lanesToShow, originalLeads]);

  const [dealLostStatusId, setDealLostStatusId] = useState(-1);
  const [dealWonStatusId, setDealWonStatusId] = useState(-1);

  useEffect(() => {
    async function fetch() {
      const response = await leadStatusListQueryFn({ api });
      const dealWon = response.find((s) => s.presetType === "DEAL_CLOSED");
      if (dealWon) {
        setDealWonStatusId(dealWon.id);
      }
      const dealLost = response.find((s) => s.presetType === "DEAL_LOST");
      if (dealLost) {
        setDealLostStatusId(dealLost.id);
      }
    }
    fetch();
  }, [api]);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);
  const parentElement = useRef<HTMLDivElement>(null);

  const sectionRef = useRef<HTMLDivElement>(null);

  const [scrollDirection, setScrollDirection] = useState<number>();

  useEffect(() => {
    if (!scrollDirection) return;

    const el = sectionRef.current;
    if (!el) return;

    const speed = 10;

    const intervalId = setInterval(() => {
      el.scrollLeft += speed * scrollDirection;
    }, 5);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollDirection, sectionRef.current]);

  useEffect(() => {
    const handleMouseMove = (event: any) => {
      const el = sectionRef.current;
      if (!isDraggingLead || !el) return;
      const isOverflowing = el.scrollWidth > el.clientWidth;
      if (!isOverflowing) return;

      const { left, right } = el.getBoundingClientRect();
      const xPos = event.clientX;
      const threshold = 200;

      const newScrollDirection =
        xPos < left + threshold ? -1 : xPos > right - threshold ? 1 : undefined;
      if (newScrollDirection !== scrollDirection) {
        setScrollDirection(newScrollDirection);
      } else {
        setScrollDirection(undefined);
      }
    };
    if (isDraggingLead) {
      window.addEventListener("mousemove", handleMouseMove);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      setScrollDirection(undefined);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      setScrollDirection(undefined);
    };
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDraggingLead, sectionRef.current]);

  return (
    <div className="relative -mb-4 flex h-full w-full">
      <div className="absolute inset-0 flex max-h-full w-full flex-row gap-4">
        <DndContext
          onDragStart={(event) => {
            if (event?.active?.data?.current?.["objectType"] === "lead") {
              setIsDraggingLead(true);
            }
          }}
          onDragEnd={onDragEnd}
          sensors={sensors}
          collisionDetection={pointerWithin}
          autoScroll={false}
        >
          <div
            className="flex h-full w-full flex-row gap-4 overflow-x-scroll pb-4"
            ref={sectionRef}
          >
            {lanes.map((lane) => {
              return (
                <StatusLane
                  key={lane.id}
                  statusId={lane.id}
                  statusName={lane.name}
                  presetType={lane.presetType}
                  leads={lane.leads}
                  order={lane.order}
                  isDraggable={lane.isDraggable}
                  isDeletable={lane.isDeletable}
                  dealWonStatusId={dealWonStatusId}
                  dealLostStatusId={dealLostStatusId}
                  parentElement={parentElement}
                />
              );
            })}
            <DraggableOverlay overlayElement={parentElement} />
          </div>
        </DndContext>
      </div>
    </div>
  );
};

function StatusLane({
  statusId,
  statusName,
  order,
  leads = [],
  isDraggable,
  presetType,
  isDeletable,
  dealLostStatusId,
  dealWonStatusId,
  parentElement,
}: {
  statusId: number;
  statusName: string;
  order: number;
  leads?: LeadPipelineItem[];
  isDraggable: boolean;
  presetType?: PresetLeadStatusType;
  isDeletable: boolean;
  dealLostStatusId: number;
  dealWonStatusId: number;
  parentElement: RefObject<HTMLDivElement>;
}) {
  const { setNodeRef: setDroppableNodeRef, over: hovering } = useDroppable({
    id: `lead-status-${statusId}`,

    data: {
      leadStatusId: statusId,
      order,
      isDraggable,
      objectType: "lane",
    } satisfies DragEventLaneData,
  });
  const {
    setNodeRef: setDraggableNodeRef,
    listeners,
    attributes,
    transform,
    active,
  } = useDraggable({
    id: `lead-lane-${statusId}`,
    data: {
      leadStatusId: statusId,
      order,
      isDraggable,
      objectType: "lane",
    } satisfies DragEventLaneData,
    disabled: !isDraggable,
  });
  const style: any = transform
    ? {
        transform: CSS.Translate.toString(transform),
      }
    : {};

  const hasLeadCardHovering =
    active?.data?.current?.["leadStatusId"] !== statusId &&
    hovering?.data?.current?.["leadStatusId"] === statusId;
  if (statusColors[statusName]) {
    style["background"] = hasLeadCardHovering
      ? statusColors[statusName].laneHovering
      : statusColors[statusName].laneBackground;
    style["borderColor"] = statusColors[statusName].laneBorder;
  } else {
    style["background"] = hasLeadCardHovering ? "#E7DAFB" : "#FBFAFF";
    style["borderColor"] = "#DDD6FE";
  }
  const isNotEditablePreset = [
    "NEW_LEAD",
    "DEAL_CLOSED",
    "DEAL_LIST",
    "TRIAL",
  ].includes(presetType || "");
  return (
    <div
      className={cn(
        "flex w-96 min-w-[25rem] flex-col overflow-hidden rounded-3xl border",
        {
          "border-2 border-dashed border-gray-300":
            (active?.data?.current as any)?.objectType === "lane" &&
            isDraggable,
        },
      )}
      ref={(ref) => {
        if (!ref) return;
        setDraggableNodeRef(ref);
        setDroppableNodeRef(ref);
      }}
      style={style}
    >
      <div
        className={cn("flex justify-between p-[calc(1rem+1px)]", {
          "!cursor-grab": isDraggable,
          "!cursor-default": !isDraggable,
        })}
        {...listeners}
        {...attributes}
      >
        <div className="flex items-center gap-2">
          <LeadBadge statusName={statusName} />
          <div className="font-semibold text-gray-500">{leads.length}</div>
        </div>
        <div
          className={cn("flex items-center", {
            hidden: isNotEditablePreset,
          })}
          data-no-dnd={true}
        >
          <LaneActionsDropdown
            leadStatusId={statusId}
            leadStatusName={statusName}
            isDeletable={isDeletable && leads.length === 0}
          />
        </div>
      </div>
      <div className="flex h-full flex-col gap-4 overflow-y-auto p-[calc(1rem+1px)] !pt-0">
        {!hasLeadCardHovering &&
          leads.map((lead) => {
            return (
              <LeadCard
                key={lead.leadId}
                lead={lead}
                dealWonStatusId={dealWonStatusId}
                dealLostStatusId={dealLostStatusId}
                disableInteractions={!!hovering}
                parentElement={parentElement}
              />
            );
          })}
      </div>
    </div>
  );
}

function LeadCard({
  lead,
  dealLostStatusId,
  dealWonStatusId,
  disableInteractions,
  parentElement,
}: {
  lead: LeadPipelineItem;
  dealLostStatusId: number;
  dealWonStatusId: number;
  disableInteractions: boolean;
  parentElement?: RefObject<HTMLDivElement>;
}) {
  const { setNodeRef, isDragging, listeners, attributes } = useDraggable({
    id: "lead-" + lead.leadId,
    data: {
      leadId: lead.leadId,
      leadStatusId: lead.leadStatusId,
      objectType: "lead",
    } satisfies DragEventLeadData,
  });
  const { api } = useGymflowModels();

  const { mutateAsync: deleteTask } = useTaskDelete({ api });
  const { timezone } = useClubSettings();
  const { mutateAsync: createTask } = useTaskCreate({ api, tz: timezone });
  const { mutateAsync: editTask } = useTaskEdit({ api, tz: timezone });
  const { mutateAsync: createMemberNote } = useNoteCreate({ api });
  const {
    createLeadNoteMutation: { mutateAsync: createLeadNote },
  } = useLeadNote({ api });
  const { notify, notifyDanger } = useContext(NotificationContext);

  const [isHoverOpen, setIsHoverOpen] = useState(false);

  const { createClubLink } = usePortalRoutes();
  const history = useHistory();

  const { setEditingTaskId } = useEditOrCreateTask();
  const result = (
    <div
      className={cn(
        "flex min-w-[22.5rem] flex-col gap-4 rounded-lg border border-gray-100 bg-white p-4 drop-shadow",
        {
          "z-50 opacity-50": isDragging,
        },
      )}
      ref={setNodeRef}
    >
      <div className="flex items-center justify-between gap-2">
        <div className="flex gap-2">
          <div className="flex max-w-[14rem] flex-col">
            <div
              className="inline-block cursor-pointer"
              onMouseOver={() => {
                if (isDragging || disableInteractions) {
                  return;
                }
                setIsHoverOpen(true);
              }}
              onMouseLeave={() => setIsHoverOpen(false)}
            >
              <MemberCard
                isOpen={isHoverOpen}
                leadId={lead.leadId}
                memberId={lead.userMemberId}
              >
                <div
                  className="overflow-y-hidden text-ellipsis font-bold"
                  onClick={() => {
                    if (lead.userMemberId) {
                      history.push(
                        createClubLink(
                          RouteLayout.Staff,
                          RouteFeature.UserMember.replace(
                            ":id",
                            lead.userMemberId,
                          ),
                        ),
                      );
                    } else {
                      history.push(
                        createClubLink(
                          RouteLayout.Staff,
                          RouteFeature.LeadProfile.replace(
                            ":id",
                            lead.leadId.toString(),
                          ),
                        ),
                      );
                    }
                  }}
                >{`${lead.firstName} ${lead.lastName}`}</div>
              </MemberCard>
            </div>

            <div className="overflow-hidden text-ellipsis font-normal text-gray-700">
              {lead.email}
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <LeadActionsDropdown
            className="h-9 w-9 min-w-0"
            dealLostStatusId={dealLostStatusId}
            dealWonStatusId={dealWonStatusId}
            lead={lead}
            showCreateTaskAndNoteResponsive
          />

          <div
            className="hidden items-center lg:flex"
            {...listeners}
            {...attributes}
          >
            <DragAndDropIcon
              className="h-6 w-6"
              pathClassName="fill-gray-500"
            />
          </div>
        </div>
      </div>
      <div className="hidden flex-col gap-4 border-t border-t-gray-100 pt-5 lg:flex">
        {lead.leadTasks && (
          <div className="flex flex-col gap-2">
            {lead.leadTasks.map((task) => {
              return (
                <div className="flex justify-between gap-4" key={task.taskId}>
                  <div className="flex items-center gap-4">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      className="text-secondary-600 focus:ring-secondary-600 h-4 w-4 border-gray-300"
                      onChange={async () => {
                        try {
                          await editTask({
                            taskId: task.taskId,
                            patchedFields: { complete: true },
                          });
                        } catch (e) {
                          notifyDanger(e);
                        }
                      }}
                    />

                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        //@ts-ignore
                        setEditingTaskId(task.taskId);
                      }}
                    >
                      {task.taskName}
                    </div>
                  </div>
                  <div className="mr-2">
                    <FontAwesomeIcon
                      onClick={async () => {
                        try {
                          await deleteTask(task.taskId);
                        } catch (e) {
                          notifyDanger(e);
                        }
                      }}
                      className="cursor-pointer text-xl text-gray-400 hover:text-gray-500"
                      icon={faClose}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="flex gap-2">
          <CheckDoneIcon pathClassName="stroke-gray-500" />
          <input
            className="flex-1 outline-none"
            type="text"
            placeholder="Add Task..."
            onKeyDown={async (e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                e.preventDefault();
                const payload: {
                  name: string;
                  relatedUserIdList?: string[];
                  relatedLeadIdList?: number[];
                } = { name: e.currentTarget.value };
                if (lead.userMemberId) {
                  payload.relatedUserIdList = [lead.userMemberId];
                } else {
                  payload.relatedLeadIdList = [lead.leadId];
                }
                e.currentTarget.value = "";
                try {
                  await createTask(payload);
                } catch (e) {
                  notifyDanger(e);
                }
              }
            }}
          />
        </div>
        <div className="flex gap-2">
          <FilePlusIcon pathClassName="stroke-gray-500" />
          <input
            className="flex-1 outline-none"
            type="text"
            placeholder="Add Note..."
            onKeyDown={async (e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                e.preventDefault();
                try {
                  const content = e.currentTarget.value;
                  e.currentTarget.value = "";
                  e.currentTarget.blur();
                  if (lead.userMemberId) {
                    createMemberNote({
                      fields: {
                        userMemberId: lead.userMemberId,
                        content,
                      },
                    });
                  } else {
                    createLeadNote({
                      newNote: {
                        leadId: lead.leadId,
                        content,
                      },
                    });
                  }

                  notify({ message: "Note added." });
                } catch (e) {
                  notifyDanger(e);
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );

  if (isDragging && parentElement?.current) {
    return (
      <>
        {createPortal(result, parentElement.current)}
        {result}
      </>
    );
  }
  return result;
}

interface Lane {
  id: number;
  name: string;
  order: number;
  leads: LeadPipelineItem[];
  presetType?: PresetLeadStatusType;
  isDraggable: boolean;
  isDeletable: boolean;
}

interface DragEventLeadData {
  objectType: "lead";
  leadId: number;
  leadStatusId: number;
}

interface DragEventLaneData {
  objectType: "lane";
  leadStatusId: number;
  order: number;
  isDraggable: boolean;
}

const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
  let cur = event.target as HTMLElement;

  while (cur) {
    if (cur.dataset && cur.dataset["noDnd"]) {
      return false;
    }
    cur = cur.parentElement as HTMLElement;
  }

  return true;
};

export class MouseSensor extends LibMouseSensor {
  static override activators = [
    { eventName: "onMouseDown", handler },
  ] as (typeof LibMouseSensor)["activators"];
}

export class TouchSensor extends LibTouchSensor {
  static override activators = [
    { eventName: "onTouchStart", handler },
  ] as (typeof LibTouchSensor)["activators"];
}
