import {
  useAppointmentSummary,
  useMemberPaymentMethodList,
} from "@gymflow/api";
import { PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { formatCurrency, pluralize } from "@gymflow/helpers";
import {
  AppointableDtoPricingModel,
  AppointmentPaymentStatus,
  PaymentMethodDTO,
  UserMemberSubscriptionBean,
} from "@gymflow/types";
import classNames from "classnames";
import moment from "moment-timezone";
import { useContext, useEffect } from "react";

import { ModalContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  Badge,
  Button,
  Checkbox,
  InputWithSaveButton,
  PlusIcon,
} from "../../atoms";
import { AddPaymentMethodModal } from "../AddPaymentMethodModal";
import { PaymentStatusBadge } from "./PaymentStatusBadge";

export function AppointmentPayment({
  appointmentId,
  appointableId,
  paymentStatus,
  appointablePrice,
  appointableSessionCost,
  paymentMethods,
  memberId,
  memberName,
  setIsTabOpen,
  setPaymentMethodId,
  paymentMethodId,
  setPaymentUnit,
  paymentUnit,
  setPromoCode,
  promoCode,
  appointableName,
  pricingModel,
}: {
  paymentStatus: AppointmentPaymentStatus;
  appointablePrice?: number;
  appointableSessionCost?: number;
  paymentUpfront: boolean;
  appointmentId: number;
  appointableId: number;
  paymentMethods?: PaymentMethodDTO[];
  memberId: string;
  memberName: string;
  cardRequired: boolean;
  setIsTabOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentMethodId: React.Dispatch<React.SetStateAction<string | undefined>>;
  paymentMethodId?: string;
  setPaymentUnit: React.Dispatch<
    React.SetStateAction<"CARD" | "CREDITS" | undefined>
  >;
  paymentUnit?: "CARD" | "CREDITS";
  setPromoCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  promoCode?: string;
  appointableName: string;
  pricingModel: AppointableDtoPricingModel;
}) {
  const { api } = useGymflowModels();
  const {
    data: summary,
    isFetching: isLoadingSummary,
    isSuccess,
  } = useAppointmentSummary(
    {
      api,
      opts: {
        appointableId,
        userMemberId: memberId,
        appointmentPaymentPostDTO: {
          appointmentId,
          paidWithSessions: paymentUnit === "CREDITS",
          promotionCode: !!promoCode ? promoCode : undefined,
          paymentMethodId: paymentUnit === "CARD" ? paymentMethodId : undefined,
        },
      },
    },
    { enabled: !!paymentUnit },
  );
  const defaultPaymentMethod = paymentMethods?.find(
    (pm) => pm.defaultPaymentMethod,
  );
  const settings = useClubSettings();

  useEffect(() => {
    const getDefaultState = (): "CARD" | "CREDITS" | undefined => {
      if (!isSuccess || paymentUnit !== undefined) {
        return;
      }
      if (pricingModel === "FREE") {
        return "CARD";
      }
      if (appointableSessionCost === undefined) {
        return "CARD";
      }

      if (appointablePrice === undefined) {
        return "CREDITS";
      }

      if (
        (summary?.paidWithSessions &&
          summary.sessionPackUsage.result !== "NOT_ENOUGH_SESSIONS") ||
        !defaultPaymentMethod
      ) {
        return "CREDITS";
      }
      return "CARD";
    };
    let result = getDefaultState();
    if (result) {
      setPaymentUnit(result);
    }
  }, [
    appointablePrice,
    appointableSessionCost,
    defaultPaymentMethod,
    isSuccess,
    paymentUnit,
    pricingModel,
    setPaymentUnit,
    summary,
  ]);

  const renderUnpaid = () => {
    if (paymentStatus !== "UNPAID") {
      return null;
    }
    return (
      <>
        <div className="mx-8 mt-4 flex">
          <Button
            onClick={() => setPaymentUnit("CARD")}
            className={classNames("w-full focus:outline-none", {
              "!bg-gray-100": paymentUnit === "CARD",
              "!rounded-r-none": appointableSessionCost !== undefined,
              hidden: appointablePrice === undefined,
            })}
          >
            Card
          </Button>
          <Button
            onClick={() => setPaymentUnit("CREDITS")}
            className={classNames("w-full focus:outline-none", {
              "bg-gray-100": paymentUnit === "CREDITS",
              "!rounded-l-none": appointablePrice !== undefined,
              hidden: appointableSessionCost === undefined,
            })}
          >
            Credits
          </Button>
        </div>
        {paymentUnit === "CARD" && (
          <CardDetails
            value={paymentMethodId}
            onChange={(paymentMethod) => {
              setPaymentMethodId(paymentMethod);
            }}
            memberId={memberId}
            memberName={memberName}
            setIsTabOpen={setIsTabOpen}
          />
        )}
        {paymentUnit === "CREDITS" && (
          <CreditPackDetails
            sessionPackCalculations={
              summary?.paidWithSessions === true
                ? summary.sessionPackUsage.sessionPackUsageCalculations
                : undefined
            }
          />
        )}
        <div className="flex flex-col gap-2 border-t border-gray-200 py-4">
          <div className="mx-8 text-sm font-semibold text-gray-700">
            Checkout
          </div>
          {paymentUnit === "CARD" && (
            <>
              <div className="mx-8">Promotional Code</div>
              <div className="mx-8">
                <InputWithSaveButton
                  onChange={(newPromoCode) => {
                    setPromoCode(newPromoCode);
                  }}
                  value={
                    promoCode &&
                    summary?.paidWithSessions === false &&
                    summary.cardUsage.validPromotionCode === "YES"
                      ? promoCode
                      : ""
                  }
                  isLoading={isLoadingSummary}
                />
              </div>
              <div
                className={classNames("text-error-600 mx-8", {
                  hidden: !(
                    promoCode &&
                    summary?.paidWithSessions === false &&
                    summary.cardUsage.validPromotionCode !== "YES"
                  ),
                })}
              >
                Promotion code is not valid.
              </div>
            </>
          )}
          <div className="mx-8 flex justify-between">
            <div>{appointableName}</div>
            <div>
              {paymentUnit === "CARD" &&
                summary?.paidWithSessions === false &&
                formatCurrency(
                  summary.cardUsage.upfrontPriceDetails
                    .totalAmountBeforeDiscount,
                  settings.defaultCurrency,
                )}
              {paymentUnit === "CREDITS" &&
                appointableSessionCost !== undefined &&
                `${appointableSessionCost} ${pluralize(
                  "Credit",
                  "Credits",
                  appointableSessionCost,
                )}`}
            </div>
          </div>
          <div
            className={classNames("mx-8 flex justify-between", {
              hidden:
                isLoadingSummary ||
                paymentUnit !== "CARD" ||
                !promoCode ||
                (summary?.paidWithSessions === false &&
                  summary.cardUsage.validPromotionCode !== "YES"),
            })}
          >
            <div>Discount</div>
            <div>
              {summary?.paidWithSessions === false &&
                formatCurrency(
                  summary
                    ? -(summary?.cardUsage.upfrontPriceDetails
                        .totalAmountDifference as number)
                    : 0,
                  settings.defaultCurrency,
                )}
            </div>
          </div>
          <div className="mx-8 flex justify-between text-base font-bold text-gray-700">
            <div className="text-base font-bold text-gray-700">Amount Due</div>
            <div>
              {paymentUnit === "CARD" &&
                summary?.paidWithSessions === false &&
                formatCurrency(
                  summary.cardUsage.upfrontPriceDetails.totalAmountToPay,
                  settings.defaultCurrency!,
                )}
              {paymentUnit === "CREDITS" &&
                appointableSessionCost !== undefined &&
                `${appointableSessionCost} ${pluralize(
                  "Credit",
                  "Credits",
                  appointableSessionCost,
                )}`}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="mx-8 flex justify-between">
        <div className="font-medium text-gray-900">Payment Status</div>
        <div>
          <PaymentStatusBadge paymentStatus={paymentStatus} />
        </div>
      </div>
      {renderUnpaid()}
    </div>
  );
}

function CardDetails({
  value,
  onChange,
  memberId,
  memberName,
  setIsTabOpen,
}: {
  value?: string;
  onChange: (paymentMethod?: string) => void;
  memberId: string;
  memberName: string;
  setIsTabOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { setModal, hide: hideModal } = useContext(ModalContext);

  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: paymentMethods } = useMemberPaymentMethodList({
    api,
    memberId,
    clubId,
  });

  useEffect(() => {
    if (paymentMethods) {
      const defaultMethod = paymentMethods.find((pm) => {
        return pm.defaultPaymentMethod;
      });
      if (defaultMethod) {
        onChange(defaultMethod.id);
      }
    }
  }, []);
  const showAddPaymentMethodAlert = () => {
    setIsTabOpen(false);
    return setModal(
      <AddPaymentMethodModal
        cardHolderName={memberName}
        memberId={memberId}
        onClose={() => {
          hideModal();
          setIsTabOpen(true);
        }}
        onConfirm={async (newPaymentMethodId) => {
          hideModal();
          setIsTabOpen(true);

          if (newPaymentMethodId) {
            onChange(newPaymentMethodId);
          }
        }}
      />,
    );
  };

  return (
    <div className="mx-8">
      <div className="text-sm font-semibold text-gray-700">Card Details</div>
      <div className="text-sm font-normal text-gray-600">
        Select or add a payment method.
      </div>
      <div className="mt-4 flex max-h-48 flex-col gap-2 overflow-y-auto">
        {paymentMethods?.map((paymentMethod) => {
          return (
            <div
              className={classNames("cursor-pointer rounded border p-4", {
                "border-gray-200": paymentMethod.id !== value,
                "border-primary-200": paymentMethod.id === value,
              })}
              onClick={() => {
                onChange(paymentMethod.id);
              }}
              key={paymentMethod.id}
            >
              <div className="flex justify-between">
                <div className="text-sm font-bold text-gray-700">
                  Card ending in {paymentMethod.last4Digits}
                </div>
                <div>
                  <Checkbox
                    onChange={(newValue) => {
                      if (newValue) {
                        onChange(paymentMethod.id);
                      } else {
                        onChange(undefined);
                      }
                    }}
                    value={value === paymentMethod.id}
                  />
                </div>
              </div>
              <div className="text-sm font-normal text-gray-600">
                Expiry {paymentMethod.expMonth}/{paymentMethod.expYear}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="mt-4 flex cursor-pointer gap-2"
        onClick={showAddPaymentMethodAlert}
      >
        <div>
          <PlusIcon pathClassName="stroke-primary-600" />
        </div>
        <div className="text-primary-600 text-sm font-semibold">
          Add new payment method
        </div>
      </div>
    </div>
  );
}

function CreditPackDetails({
  sessionPackCalculations,
}: {
  sessionPackCalculations?: {
    readonly sessionPack: UserMemberSubscriptionBean;
    readonly numberOfSessionsUsed: number;
  }[];
}) {
  const settings = useClubSettings();

  return (
    <div className="mx-8">
      <div className="text-sm font-semibold text-gray-700">Credits</div>
      <div className="text-sm font-normal text-gray-600">
        Charge for this appointment with credits available on the user accounts.
        Credits will be deducted from the pack expiring soonest.
      </div>
      <div className="mt-4 flex max-h-48 flex-col gap-2 overflow-y-auto">
        {sessionPackCalculations ? (
          sessionPackCalculations?.map(
            ({
              sessionPack: { id, name, sessionsLeft, endDate, sessionPackBean },
            }) => {
              return (
                <div
                  key={id}
                  className="cursor-pointer rounded border border-gray-200 p-4"
                >
                  <div className="flex justify-between">
                    <div className="text-sm font-bold text-gray-700">
                      {name}
                    </div>
                    <div>
                      <Badge intent="primary">
                        {sessionPackBean?.sessionsUnlimited
                          ? "∞"
                          : `${sessionsLeft} ${pluralize(
                              "Credit",
                              "Credits",
                              sessionsLeft!,
                            )}`}
                      </Badge>
                    </div>
                  </div>
                  <div className="text-sm font-normal text-gray-600">
                    Expiry{" "}
                    {moment(endDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
                      settings.date_format,
                    )}
                  </div>
                </div>
              );
            },
          )
        ) : (
          <div>Member doesn&apos;t have credit packs.</div>
        )}
      </div>
    </div>
  );
}
