import { useAbility } from "@casl/react";
import {
  AlertContext,
  InvoiceStatus,
  NotificationContext,
  PaymentConfirmationStatus,
  useParseErrors,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { Button } from "reactstrap";

import { AbilityContext, Can, Subject, Verb } from "../../permissions";
import useGymflowModels from "../../store";
import { Spinner } from "../atoms";
import AdjustInvoiceAlert from "./AdjustInvoiceAlert";
import PayInvoiceAlert from "./PayInvoiceAlert";
import RefundInvoiceAlert from "./RefundInvoiceAlert";
import WriteOffInvoiceAlert from "./WriteOffInvoiceAlert";

function InvoiceButtons({
  number,
  status,
  chargeableAmount,
  clubId,
  userMemberId,
  currency,
  writableOff,
  invoiceUrl,
  refundableAmount,
  refreshInvoices,
  paymentIntentIdForAuthorization,
  requestAuthorization,
  cancelAuthorization,
  getInvoiceFile,
  totalAmount,
  creditInvoice,
  debitInvoice,
  collectInvoice,
  payInvoice,
  refundInvoice,
  writeOffInvoice,
}) {
  const { api } = useGymflowModels();
  const ability = useAbility(AbilityContext);
  const toast = useContext(NotificationContext);
  const alert = useContext(AlertContext);
  const { show: showPaymentAuthorizationAlert } = usePaymentAuthorizationAlert();
  const parseError = useParseErrors();

  const renderRequestAuthorization = (number, paymentIntentIdForAuthorization) => {
    return (
      <Button
        size="sm"
        className="btn-link"
        onClick={() => requestAuthorization({ number, paymentIntentIdForAuthorization })}
      >
        Request Authorization
      </Button>
    );
  };

  const renderCancelAuthorization = (paymentIntentIdForAuthorization) => {
    return (
      <Button size="sm" className="btn-link" onClick={() => cancelAuthorization({ paymentIntentIdForAuthorization })}>
        Cancel Authorization
      </Button>
    );
  };

  const renderAdjustButton = (number, chargeableAmount) => {
    return (
      <Can I={Verb.Credit} a={Subject.Invoices}>
        <LinkButton
          onClick={() => {
            alert.setAlert(
              <AdjustInvoiceAlert
                onCancel={alert.hide}
                onConfirm={async ({ amount }) => {
                  try {
                    if (amount < 0) {
                      await creditInvoice(userMemberId, clubId, number, Math.abs(amount));
                      toast.notify({ message: "Credit was added to invoice successfully." });
                    } else {
                      await debitInvoice(userMemberId, clubId, number, Math.abs(amount));
                      toast.notify({ message: "Debit was added to invoice successfully." });
                    }
                  } catch (e) {
                    await parseError(e.response);
                  }
                  alert.hide();
                  refreshInvoices();
                }}
                totalAmount={totalAmount}
                chargeableAmount={chargeableAmount}
                currency={currency}
              />,
            );
          }}
        >
          Adjust
        </LinkButton>
      </Can>
    );
  };

  const renderPayButton = (number, chargeableAmount) => {
    let buttonText;
    let text;
    let title;
    let confirmButtonText;
    let apiCall;
    if (ability.can(Verb.Collect, Subject.Invoices)) {
      buttonText = "Collect";
      title = "Collect Invoice";
      confirmButtonText = "Collect";
      text = "Do you want to collect this invoice?";
      apiCall = async (amount) => {
        const response = await collectInvoice(userMemberId, clubId, number, amount);
        if (response.data?.status === InvoiceStatus.PaymentAuthorization) {
          return showPaymentAuthorizationAlert({
            paymentIntentIdForAuthorization: response.data.paymentIntentIdForAuthorization,
            confirmPayment: api.strongCustomerAuthorizationApi.confirmPayment,
            messageText: (
              <>
                The user must authorize this payment before it will be processed.
                <br />
                Please ask the user to authorize the payment by clicking the link sent to their email.
              </>
            ),
          });
        }
      };
    } else if (ability.can(Verb.Pay, Subject.Invoices)) {
      buttonText = "Pay";
      title = "Pay Invoice";
      confirmButtonText = "Pay";
      text = "Do you want to pay this invoice?";
      apiCall = async (amount) => {
        const response = await payInvoice(clubId, number, amount);

        if (response.data?.status === InvoiceStatus.PaymentAuthorization) {
          return showPaymentAuthorizationAlert({
            paymentIntentIdForAuthorization: response.data.paymentIntentIdForAuthorization,
            confirmPayment: api.strongCustomerAuthorizationApi.confirmPayment,
            messageText: (
              <>
                The user must authorize this payment before it will be processed.
                <br />
                Please ask the user to authorize the payment by clicking the link sent to their email.
              </>
            ),
            hideCancel: true,
          });
        }
      };
    } else {
      return null;
    }

    return (
      <LinkButton
        onClick={() => {
          alert.setAlert(
            <PayInvoiceAlert
              onCancel={alert.hide}
              onConfirm={async ({ amount }) => {
                try {
                  const paymentConfirmationResult = await apiCall(amount);
                  alert.hide();

                  if (
                    !paymentConfirmationResult ||
                    paymentConfirmationResult.status === PaymentConfirmationStatus.Success
                  ) {
                    toast.notify({ message: "Invoice was paid successfully." });
                  } else if (paymentConfirmationResult.status === PaymentConfirmationStatus.Waiting) {
                    toast.notify({ message: "Awaiting payment, check later.", type: "warning" });
                  } else if (paymentConfirmationResult.status === PaymentConfirmationStatus.Failed) {
                    toast.notify({ message: "Payment Failed.", type: "danger" });
                  }
                } catch (e) {
                  alert.hide();
                  await parseError(e.response);
                }
                refreshInvoices();
              }}
              maxAmount={chargeableAmount}
              title={title}
              confirmButtonText={confirmButtonText}
              text={text}
              currency={currency}
            />,
          );
        }}
      >
        {buttonText}
      </LinkButton>
    );
  };

  const renderRefundButton = (number, refundableAmount) => {
    if (refundableAmount <= 0) {
      return null;
    }

    return (
      <Can I={Verb.Refund} a={Subject.Invoices}>
        <LinkButton
          onClick={() => {
            alert.setAlert(
              <RefundInvoiceAlert
                onConfirm={async () => {
                  try {
                    await refundInvoice(userMemberId, clubId, number);
                    alert.hide();
                    toast.notify({ message: "Invoice was refunded successfully." });
                  } catch {
                    alert.hide();
                    alert.showError("An error occurred while trying to refund the invoice.");
                  }
                  refreshInvoices();
                }}
                onCancel={alert.hide}
                amount={refundableAmount}
                currency={currency}
              />,
            );
          }}
        >
          Refund
        </LinkButton>
      </Can>
    );
  };

  const renderWriteOffButton = (number, writableOff) => {
    if (!writableOff) {
      return null;
    }

    return (
      <Can I={Verb.WriteOff} a={Subject.Invoices}>
        <LinkButton
          onClick={() => {
            alert.setAlert(
              <WriteOffInvoiceAlert
                onConfirm={async () => {
                  try {
                    await writeOffInvoice(userMemberId, clubId, number);
                    alert.hide();
                    toast.notify({ message: "Invoice was write off successfully." });
                  } catch {
                    alert.hide();
                    alert.showError("An error occurred while trying to write off the invoice.");
                  }
                  refreshInvoices();
                }}
                onCancel={alert.hide}
              />,
            );
          }}
        >
          Write Off
        </LinkButton>
      </Can>
    );
  };

  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const renderDownloadButton = () => {
    return (
      <LinkButton
        onClick={async () => {
          setIsDownloadLoading(true);
          const { data: html } = await getInvoiceFile(clubId, number);

          const blob = new Blob([html], { type: "text/html" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `Gymflow-${number}.html`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          setIsDownloadLoading(false);
        }}
      >
        <div className="flex flex-row">
          {isDownloadLoading && <Spinner className="text-gray-300" />}
          View
        </div>
      </LinkButton>
    );
  };

  const renderActionsPerStatusButtons = () => {
    switch (status) {
      case InvoiceStatus.PastDue:
        return (
          <>
            {renderAdjustButton(number, chargeableAmount)}
            {renderPayButton(number, chargeableAmount)}
            {renderWriteOffButton(number, writableOff)}
          </>
        );
      case InvoiceStatus.PaymentAuthorization:
        return (
          <>
            {renderRequestAuthorization(number, paymentIntentIdForAuthorization)}
            {renderCancelAuthorization(paymentIntentIdForAuthorization)}
          </>
        );
      case InvoiceStatus.Scheduled:
        return renderAdjustButton(number, chargeableAmount);
      case InvoiceStatus.Paid:
        return renderRefundButton(number, refundableAmount);
      default:
        return null;
    }
  };

  return (
    <>
      {renderActionsPerStatusButtons()}
      {renderDownloadButton(invoiceUrl)}
    </>
  );
}

const LinkButton = ({ children, ...props }) => (
  <Button size="sm" className="btn-link" {...props}>
    {children}
  </Button>
);

InvoiceButtons.propTypes = {
  number: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(InvoiceStatus)).isRequired,
  chargeableAmount: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
  clubId: PropTypes.number.isRequired,
  userMemberId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  writableOff: PropTypes.bool,
  invoiceUrl: PropTypes.string.isRequired,
  refundableAmount: PropTypes.number,
  refreshInvoices: PropTypes.func.isRequired,
  paymentIntentIdForAuthorization: PropTypes.string,
  getInvoiceFile: PropTypes.func.isRequired,
  creditInvoice: PropTypes.func.isRequired,
  debitInvoice: PropTypes.func.isRequired,
  collectInvoice: PropTypes.func.isRequired,
  payInvoice: PropTypes.func.isRequired,
  refundInvoice: PropTypes.func.isRequired,
  writeOffInvoice: PropTypes.func.isRequired,
};

export default InvoiceButtons;
