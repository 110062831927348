import {
  AppointmentPurchaseSummaryDTO,
  AppointmentPurchaseSummaryPostDTO,
} from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { appointmentQueryKeys } from "./appointmentQueryKeys";

export async function appointmentSummaryQueryFn({
  api,
  filter,
}: {
  api: AppointmentSummaryApi;
  filter: AppointmentPurchaseSummaryPostDTO;
}) {
  const result = await api.appointmentApi.summary(filter);
  return result.data;
}

export function useAppointmentSummary(
  {
    api,
    opts,
  }: {
    api: AppointmentSummaryApi;
    opts: AppointmentPurchaseSummaryPostDTO;
  },
  queryOpts?: UseQueryOptions<unknown, unknown, AppointmentPurchaseSummaryDTO>,
) {
  const result = useQuery({
    queryKey: appointmentQueryKeys.summary(opts),
    initialData: null,
    queryFn: () => appointmentSummaryQueryFn({ api, filter: opts }),
    ...queryOpts,
  });
  return result;
}

interface AppointmentSummaryApi {
  appointmentApi: {
    summary: (
      params: AppointmentPurchaseSummaryPostDTO,
    ) => Promise<{ data: AppointmentPurchaseSummaryDTO }>;
  };
}
